
import axios from 'axios';




async function GetAndroidApps() {
    try {
        const response = await axios.post("https://apiv2.mobileartsme.com/af_getapps_new",
            { platformId: "0"},
        );

        const res = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;
        const data = res.Result;

        const apkApps = [];
        const googleplay = [];
        const huawei = [];

        data.forEach(app => {
            if (app.StoreLink === null) {
                apkApps.push({ ...app, Screenshots: app.Screenshots || [] });
            } else if (app.StoreLink.includes("appgallery.huawei.com")) {
                huawei.push({ ...app, Screenshots: app.Screenshots || [] });
            } else {
                if(app.Platform != "IOS"){
                    googleplay.push({ ...app, Screenshots: app.Screenshots || [] });
                }
            }
        });

        return { apkApps, googleplay, huawei };
    } catch (error) {
        console.error("Error fetching Android apps:", error);
        return { apkApps: [], googleplay: [], huawei: [] };
    }
}

async function GetIOSApps() {
    try {
        const response = await axios.post(
            "https://apiv2.mobileartsme.com/af_getapps_new",
            { platformId: "3" },
            {
                headers: { 'Content-Type': 'application/json' },
                
            }
        );

        const res = response.data.Result;
        return res || [];
    } catch (error) {
        console.error("Error fetching iOS apps:", error);
        return [];
    }
}

async function GetGames() {
    try {
        const response = await axios.get("https://apiv2.mobileartsme.com/gbox_getcontent_new",
            {
                headers: { 'Content-Type': 'application/json' },
                mode: 'no-cors',
            }
        );

        const res = response.data.Content[0].HTML5;

        return res || [];
    } catch (error) {
        console.error("Error fetching games:", error);
        return [];
    }
}

async function GetVideos() {
    try {
        const response = await axios.get(
            "https://apiv2.mobileartsme.com/mobibase_content?language=en&ispremium=true",
           
        );

        const res = response.data.Content[0].Videos;
        return res || [];
    } catch (error) {
        console.error("Error fetching videos:", error);
        return [];
    }
}

async function GetLiveChannels() {
    try {
        const response = await axios.get(
            "https://apiv2.mobileartsme.com/av_tvchannels",
            {
                headers: { 'Content-Type': 'application/json' },
                mode: 'no-cors',
            }
        );

        return response.data.Channels || [];
    } catch (error) {
        console.error("Error fetching live channels:", error);
        return [];
    }
}

// export let storedData = null; // Initialize the storedData variable

// export async function GetAllData() {
//     // Check if the data is already in storedData
//     if (storedData) {
//         return storedData; // Return the cached data
//     }

//     // Fetch data from APIs
//     const AndroidApps = await GetAndroidApps();
//     const IOSApps = await GetIOSApps();
//     const Games = await GetGames();
//     const Videos = await GetVideos();
//     const LiveChannels = await GetLiveChannels();

//     // Combine all fetched data
//     storedData = { AndroidApps, IOSApps, Games, Videos, LiveChannels };

//     return storedData; // Return the newly fetched data
// }




export async function GetAllData() {
    // Check if all data exists in session storage
    const storedData = sessionStorage.getItem('AF_AllData');
    if (storedData) {
        return JSON.parse(storedData);
    }
    const AndroidApps = await GetAndroidApps();
    const IOSApps = await GetIOSApps();
    const Games = await GetGames();
    const Videos = await GetVideos();
    const LiveChannels = await GetLiveChannels();

    const allData = { AndroidApps, IOSApps, Games, Videos, LiveChannels };
    sessionStorage.setItem('AF_AllData', JSON.stringify(allData));

    return allData;
}



export function Logout() {
    sessionStorage.removeItem("AF_Login_Data")
    window.location.reload()
}





async function AutoLogin() {
    const AF_Login_Data = sessionStorage.getItem("AF_Login_Data");
    const urlParams = window.location.pathname;

    if (urlParams === '/' && AF_Login_Data) {
        window.location.href = '/application';
        
    }
    else if (urlParams !== "/" && !AF_Login_Data && urlParams !== "/AppDetails" && urlParams !== "/ContactUs") {
        window.location.href = '/';
    }

}




AutoLogin()

export { GetAndroidApps, GetIOSApps, GetGames, GetVideos, GetLiveChannels };
