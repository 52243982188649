import React, { useEffect, useState } from "react";
import SidebarAnt from '../components/SidebarAnt';
import { Button, Modal, Spin } from 'antd';
import Hls from 'hls.js'; // Import HLS.js

import "../styles/Applications.css";
import Header from '../components/Header';
import PlayButton from '../assets/icons/cards/playbutton.svg';




export default function Videos() {
    const [Videos, setVideos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [videoLink, setVideoLink] = useState(''); // State to store video URL
    const [isVideoLoading, setIsVideoLoading] = useState(true); // Loader state for video
    const [videoInstance, setVideoInstance] = useState(null); // To store HLS instance

    const showModal = (videoId) => {
        setIsVideoLoading(true); // Show loader before fetching video
        setOpen(true); 
        // Fetch the video link using videoId
        fetch(`https://apiv2.mobileartsme.com/av_geturl?videoId=${videoId}`)
            .then(response => response.json())
            .then(data => {
                if (data.result) {
                    setVideoLink(data.result);  // Set the m3u8 URL
               
                    setIsVideoLoading(false);   // Stop loading if no URL found
                    // Open the modal to show video
                } else {
                    console.error('No video URL found');
                    setIsVideoLoading(false);   // Stop loading if no URL found
                }
            })
            .catch(error => {
                console.error('Error fetching video URL:', error);
                setIsVideoLoading(false);     // Stop loading on error
            });
    };

    const handleOk = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setOpen(false);
        }, 3000);
    };

    const handleCancel = () => {
        if (videoInstance) {
            videoInstance.destroy(); // Clean up HLS instance when modal is closed
        }
        setOpen(false);
    };

    useEffect(() => {
        const allData = JSON.parse(sessionStorage.getItem("AF_AllData"));
        const urlParams = new URLSearchParams(window.location.search);
        const Category = urlParams.get("Category");

        if (allData) {
            setVideos(allData.Videos[Category].Content || []);
        }

    }, [window.location.search]);




    useEffect(() => {
        if (open && videoLink) {
            const videoElement = document.getElementById('video-player');
            const hls = new Hls();
    
            // Initialize HLS.js for stream
            hls.loadSource(videoLink);  // Load stream URL, even without `.m3u8`
            hls.attachMedia(videoElement); // Attach to video element
    
            hls.on(Hls.Events.MANIFEST_PARSED, () => {
         
                setIsVideoLoading(false);  // Hide loader
            });
    
            hls.on(Hls.Events.ERROR, (event, data) => {
                if (data.fatal) {
                    console.error('HLS.js error:', data);
                    setIsVideoLoading(false);
                }
            });
    
            setVideoInstance(hls);  // Save the HLS instance for cleanup
        }
    
        return () => {
            if (videoInstance) {
                videoInstance.destroy();
            }
        };
    }, [open, videoLink]); // Watch for changes in the video URL
    

    return (
        <section className='d-flex ' style={{ height: '100vh' }}>
            <SidebarAnt />
            <div className='main-content'>
                <Header />
                <div className='Dashboard_Body'>
                    {Videos.map((video, index) => {
                        return (
                            <div
                                onClick={() => showModal(video.ID)} // Pass the video ID when clicked
                                style={{ height: '197px', cursor: 'pointer' }} className="App_Card" key={index}>
                                <div
                                    className="Video_Image"
                                    style={{
                                        backgroundImage: `url(${video.Thumbnail})`, width: '100%',
                                        height: '70%', borderRadius: '10px'
                                    }}>
                                    <img className="PlayButton" src={PlayButton} />
                                </div>

                                <div className="card_desc">
                                    <h5
                                        style={{ fontSize: '1rem' }}
                                        className="line-clamp-2" >{video.Title}</h5>
                                </div>

                            </div>
                        );
                    })}
                </div>

                {/* Modal to show the video */}
                <Modal
                    title="Video"
                    open={open}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={[]}
                    width="80%"
                    style={{ padding: 0 }}
                >
                    <div style={{ position: 'relative' }}>
                        {/* Loader before video loads */}
                        {isVideoLoading && (
                            <div 
                            style={{ position: 'absolute', 
                            top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                <Spin size="large" />
                            </div>
                        )}
                        {/* Video player container */}
                        <div id="video-player-container" 
                        style={{ width: '100%', height: '100%' }}>
                            {videoLink && !isVideoLoading && (
                                <video id="video-player" controls width="100%" height="100%">
                                    <source src={videoLink} type="application/x-mpegURL" />
                                    Your browser does not support HTML5 video.
                                </video>
                            )}
                        </div>
                    </div>
                </Modal>
            </div>
        </section>
    );
}
