import React, { useEffect, useState } from "react";
import SidebarAnt from '../components/SidebarAnt';
import { GetAllData, GetLiveChannels } from "../functions/apis";
import "../styles/Applications.css";
import Header from '../components/Header';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function Games() {
    const [Games, setGames] = useState([]);
    const [GameLink, setGameLink] = useState('');

    useEffect(() => {
        const allData = JSON.parse(sessionStorage.getItem("AF_AllData"));
        const urlParams = new URLSearchParams(window.location.search);
        const Category = urlParams.get("Category");

        if (allData) {
            setGames(allData.Games[Category].Content || []);
        }
        console.log("Games page");
       
    }, [window.location.search]);


    // useEffect(() => {
    //     const fetchGames = () => {
    //         const urlParams = new URLSearchParams(window.location.search);
    //         const category = urlParams.get("Category");

    //         if (storedData && storedData.Games) {
    //             // Safely access the category and set the games
    //             const categoryData = storedData.Games[category];
    //             if (categoryData && categoryData.Content) {
    //                 setGames(categoryData.Content);
    //             } else {
    //                 console.warn(`No content found for category: ${category}`);
    //             }
    //         } else {
    //             console.log("Stored data is not available yet.");
    //         }
    //     };

    //     fetchGames();
    // }, [window.location.search]);

    
    const playGame = (link) => {
        setGameLink(link);
    };

    const closeModal = () => {
        setGameLink('');
    };

    return (
        <section className='d-flex' style={{ height: '100vh' }}>
            <SidebarAnt />
            <div className='main-content'>
                <Header />
                <div className='Dashboard_Body'>
                    {Games.map((game, index) => {
                        return (
                            <div className="App_Card" key={index} data-index={game.Title}>
                                <LazyLoadImage
                                    className="Games_Icon"
                                    src={game.Thumbnail_Large}
                                    alt={game.Title}
                                    effect="blur" // Adds a blur effect while loading
                                />
                                <p className="App_Name">{game.Title}</p>
                                <button
                                    onClick={() => playGame(game.Content)}
                                    className="Play_Button_Games">
                                    Play
                                </button>
                            </div>
                        );
                    })}
                </div>
            </div>

            {/* Modal */}
            {GameLink && (
                <div className="Modal_Backdrop">
                    <div className="Modal_Content">
                        <button className="Close_Button" onClick={closeModal}>
                            Close
                        </button>
                        <iframe
                            src={GameLink}
                            className="Game_Frame"
                            title="Game"
                            frameBorder="0"
                            allowFullScreen
                        ></iframe>
                    </div>
                </div>
            )}
        </section>
    );
}
