import React, { useEffect, useState } from "react";
import SidebarAnt from '../components/SidebarAnt';
import { Button, Modal, Spin } from 'antd';
import Hls from 'hls.js'; // Import HLS.js

import "../styles/Applications.css";
import Header from '../components/Header';
import PlayButton from '../assets/icons/cards/playbutton.svg';
import {GetAllData, GetLiveChannels} from '../functions/apis'




export default function Streaming() {
    const [Videos, setVideos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [videoLink, setVideoLink] = useState(''); // State to store video URL
    const [isVideoLoading, setIsVideoLoading] = useState(false); // Loader state for video
    const [videoInstance, setVideoInstance] = useState(null); // To store HLS instance

    const showModal = (link) => {
     
        setOpen(true); 
        setVideoLink(link);  // Set the m3u8 URL
    };

    const handleOk = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setOpen(false);
        }, 3000);
    };

    const handleCancel = () => {
        if (videoInstance) {
            videoInstance.destroy(); // Clean up HLS instance when modal is closed
        }
        setOpen(false);
    };

    useEffect(() => {
        const allData = JSON.parse(sessionStorage.getItem("AF_AllData"));
        const urlParams = new URLSearchParams(window.location.search);
        const Category = urlParams.get("Category");

        if (allData) {
            setVideos(allData.LiveChannels || []);
        }

    }, [window.location.search]);






    useEffect(() => {
        async function initializeVideo() {
            if (open && videoLink) {
                try {
                    // Fetch the URL to validate and ensure it's accessible
                    const videoElement = document.getElementById('video-player');
        
                    if (Hls.isSupported()) {
                        const hls = new Hls();
    
                        // Attach HLS to video element only if it's not already attached
                        if (!hls.media) {
                            hls.loadSource(videoLink); // Load stream URL
                        }
    
                        hls.on(Hls.Events.MANIFEST_PARSED, () => {
                         
                            hls.attachMedia(videoElement); // Attach to video element
                        });


                        hls.startLoad(); // Start loading the stream
                        setVideoInstance(hls); // Save the HLS instance
                    } else if (videoElement.canPlayType('application/vnd.apple.mpegurl')) {
                        // Fallback for browsers with native HLS support (e.g., Safari)
                        videoElement.src = videoLink;
                        videoElement.addEventListener('loadedmetadata', () => {
                            setIsVideoLoading(false);
                           
                        });
                    } else {
                        throw new Error('HLS not supported on this browser');
                    }
                } catch (error) {
                    console.error('Error initializing video:', error);
                    setIsVideoLoading(false);
                }
            }
        }
    
        initializeVideo();
    
        return () => {
            if (videoInstance) {
                videoInstance.destroy(); // Cleanup HLS instance
            }
        };
    }, [open, videoLink]);
    

    
    

    return (
        <section className='d-flex ' style={{ height: '100vh' }}>
            <SidebarAnt />
            <div className='main-content'>
                <Header />
                <div className='Dashboard_Body'>
                    {Videos.map((video, index) => {
                        return (
                            <div
                                onClick={() => showModal(video.ChannelURL)} // Pass the video ID when clicked
                                style={{ height: '197px', cursor: 'pointer' }} 
                                className="App_Card" key={index}>
                                <div
                                    className="Video_Image"
                                    style={{
                                        backgroundImage: `url(${video.ChannelThumbnail})`, width: '100%',
                                        height: '70%', borderRadius: '10px'
                                    }}>
                                    <img className="PlayButton" src={PlayButton} />
                                </div>

                                <div className="card_desc">
                                    <h5
                                        style={{ fontSize: '1rem' }}
                                        className="line-clamp-2" >{video.ChannelName}</h5>
                                </div>

                            </div>
                        );
                    })}
                </div>

                {/* Modal to show the video */}
                <Modal
                    title="Video"
                    open={open}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={[]}
                    width="80%"
                    style={{ padding: 0 }}
                >
                    <div style={{ position: 'relative' }}>
                        {/* Loader before video loads */}
                        {isVideoLoading && (
                            <div 
                            style={{ position: 'absolute', 
                            top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                <Spin size="large" />
                            </div>
                        )}
                        {/* Video player container */}
                        <div id="video-player-container" 
                        style={{ width: '100%', height: '100%' }}>
                            {videoLink && !isVideoLoading && (
                                <video id="video-player" controls width="100%" height="100%">
                                    <source src={videoLink} type="application/x-mpegURL" />
                                    Your browser does not support HTML5 video.
                                </video>
                            )}
                        </div>
                    </div>
                </Modal>
            </div>
        </section>
    );
}
