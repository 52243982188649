import React, { useEffect, useState, useRef } from "react";
import Logo from '../assets/icons/sidebar/appsfactory-fulllogo.svg'
import Playstore from '../assets/icons/cards/android.svg'
import Appstore from '../assets/icons/cards/appstore.svg'
import Huawei from '../assets/icons/cards/AppGallery.png'
import { Button } from "bootstrap";
import Slider from "react-slick";


export default function AppDetails() {

    const [App, setApp] = useState({});

    useEffect(() => {
        const queryString = new URLSearchParams(window.location.search);
        const encodedData = queryString.get('data'); // Get the encoded data using 'data' as the key
        if (encodedData) {
            // Validate Base64 format before decoding
            const isBase64 = /^[A-Za-z0-9+/=]+$/.test(encodedData);
            if (isBase64) {
                try {
                    const decodedString = atob(encodedData);
                    const parsedData = JSON.parse(decodedString);
                    setApp(parsedData);
                   
                } catch (error) {
                    console.error("Failed to parse JSON:", error);
                }
            } else {
                console.error("Invalid Base64 string.");
            }
        }
    }, []);

    let sliderRef = useRef(null);
    const next = () => {
        sliderRef.slickNext();
    };
    const previous = () => {
        sliderRef.slickPrev();
    };
    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };



    return (


        <div className="row"
            style={{ height: '100vh', margin: '0' }} >

            <div className="col-lg-6 p-5" style={{ backgroundColor: '#101010' }} >
                <img src={Logo} />

                <div style={{ display: 'flex', marginTop: '4rem', flexDirection: 'column', gap: '2rem' }} >
                    <a
                        href="/"
                        style={{ color: 'white', cursor: 'pointer', textDecoration: 'none' }} >
                        🡨  Back</a>

                    <div style={{ display: 'flex', height: '100px', gap: '15px' }}  >
                        <img
                            style={{ width: '110px', borderRadius: '10px' }}
                            src={App.Logo ? App.Logo : Logo} />
                        <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }} >
                            <h1 className="line-clamp-1" style={{ color: 'white' }} >
                                {App.AppName}
                            </h1>

                            <div>
                                <button
                                    className="os_button d-flex"
                                    onClick={() => window.location.href = App.StoreDeeplink || '#'}
                                >
                                    <img
                                        style={{ height: '100%' }}
                                        src={
                                            App.Platform === "IOS"
                                                ? Appstore
                                                : App.StoreDeeplink?.includes("appgallery")
                                                    ? Huawei
                                                    : Playstore
                                        }
                                        alt="App Store Icon"
                                    />
                                    <div className="d-flex flex-column align-items-start">
                                        <span style={{ fontSize: '11px', color: 'white' }}>Available on</span>
                                        <span style={{ color: 'white', fontWeight: '700' }}>
                                            {App.Platform === "IOS"
                                                ? "App Store"
                                                : App.StoreDeeplink?.includes("appgallery")
                                                    ? "App Gallery"
                                                    : "Google Play"}
                                        </span>
                                    </div>
                                </button>


                            </div>

                        </div>
                    </div>

                    <div style={{ borderTop: '1px solid #ffffff59' }} ></div>

                    <div>
                        <h2 style={{ color: 'white' }} >Description</h2>
                        <p>{App.Description ? App.Description.replace(/<p>/g, '').replace(/<\/p>/g, ''): 'No Description for this application'}</p>
                    </div>
                </div>
            </div>


            <div className="col-lg-6 p-5" style={{ backgroundColor: '#434343' }} >

                <button
                    onClick={() => window.location.href = '/#LoginSection'}
                    className="primary-button"
                    style={{ float: 'right' }} >login</button>

                <div style={{ display: 'flex', marginTop: '4rem', flexDirection: 'column', gap: '2rem' }} >

                    <div className="mt-5" >
                        <button
                            style={{
                                background: '#585858',
                                color: 'white',
                                border: 'none',
                                borderRadius: '5px'
                            }}
                            onClick={previous}>

                            <span class="bi bi-arrow-left"></span>
                        </button>

                        <button
                            style={{
                                background: '#2FDA7B',
                                color: 'white',
                                border: 'none',
                                borderRadius: '5px'
                            }}
                            onClick={next}>
                            <span class="bi bi-arrow-right"></span>
                        </button>
                    </div>

                    <div className="mt-4">
                        <div className="slider-container">
                            <Slider
                                ref={slider => {
                                    sliderRef = slider;
                                }}
                                {...settings}>

                                {App.Screenshots?.length > 0 ? (
                                    App.Screenshots.map((screenshot, index) => (

                                        <img className="Screenshots_slider"

                                            src={screenshot} />

                                    ))
                                ) : (
                                    <p>No Images available for this application.</p>
                                )}

                            </Slider>
                        </div>

                    </div>

                </div>
            </div>

        </div>
    )
}