import React, { useEffect, useState } from "react";
import Logo from '../assets/icons/sidebar/appsfactory-fulllogo.svg';
import { Button, Modal, Alert,Drawer} from 'antd';
import axios from 'axios';

export default function Footer() {
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false); // State for loading
    const [phoneNumber, setPhoneNumber] = useState(''); // State to store phone number input
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState(''); // State for Alert message
    const [alertType, setAlertType] = useState(''); // State for Alert type ('success' or 'error')

    const [OpenPrivacy, setOpenPrivacy] = useState(false);
    const [OpenTerms, setOpenTerms] = useState(false);

    const ShowTerms = () => {
      setOpenTerms(true);
    };
    const CloseTerms = () => {
      setOpenTerms(false);
    };
    const ShowPrivacy = () => {
        setOpenPrivacy(true);
      };
      const ClosePrivacy = () => {
        setOpenPrivacy(false);
      };
    
    const showModal = () => {
        setOpen(true);
    };

    const handleOk = async () => {
        setLoading(true);

        try {
            const response = await axios.post(
                "https://apiv2.mobileartsme.com/af_forgetpassword",
                { phonenumber: phoneNumber },
                { headers: { 'Content-Type': 'application/json' } }
            );
            setLoading(false);
            setOpen(false);

            if (response.data.Error === 0) {
                // If API call is successful, show success message
                setAlertMessage("Password reset link has been sent to your phone number.");
                setAlertType('success');
            } else {
                // If API call fails, show error message
                setAlertMessage(response.data.Description || "An error occurred.");
                setAlertType('error');
            }
        } catch (err) {
            console.error(err);
            setLoading(false);
            setOpen(false);
            setAlertMessage("An error occurred while resetting the password. Please try again.");
            setAlertType('error');
        }
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission
        setIsLoading(true); // Set loading to true when login starts

       

        try {
            const response = await axios.get(
                `https://apiv2.mobileartsme.com/login?phonenumber=${phone}&password=${password}&serviceid=1479&type=signin&country=LB`
            );

            const AF_Login_Data = response.data.Result;
            

            if (response.data.Error === 0) { // Ensure `error` is correctly accessed
                if (AF_Login_Data.Status === 0) {
                    // Handle successful login
                    AF_Login_Data.username = phone;
                    AF_Login_Data.password = password;
                    sessionStorage.setItem("AF_Login_Data", JSON.stringify(AF_Login_Data));
                    window.location.href = '/application'; // Redirect on successful login
                } else {
                    // Show error message from API response
                    setErrorMessage(response.data.Result.Description || "Unknown error occurred.");
                }
            } else {
                // Handle other errors
                setErrorMessage(response.data.Result || "Unknown error occurred.");
            }
        } catch (error) {
            console.error("Error during login:", error);
            setErrorMessage("An error occurred while logging in. Please try again.");
        } finally {
            setIsLoading(false); // Reset loading state
        }

        // Hide the error message after 5 seconds
        if (errorMessage) {
            setTimeout(() => {
                setErrorMessage(''); // Clear the error message
            }, 5000);
        }
    };



    const handleInputChange = (e) => {
        // Update the phone number state as the user types
        setPhoneNumber(e.target.value);
    };

    return (
        <section id="LoginSection" className="mt-5"
            style={{ background: 'black', padding: '5rem 0rem 5rem 0rem' }} >
            <div className="container" >
                <div className="row">
                    <div className="col-lg-4" >
                        <img style={{ width: '12rem' }} src={Logo} />
                        <br></br>
                        <p className="mt-4" style={{ fontSize: '0.8rem' }}>Apps Factory is an app store-portal,
                            offering a selection of mobile
                            applications for
                            <span style={{ color: '#2FDA7B' }} > Android </span>
                            and
                            <span style={{ color: '#2FDA7B' }} > IOS </span>
                            phones, with entertaining online games, video streaming, and utility apps that suits all ages.</p>

                    </div>

                    <div className="col-lg-6 d-flex flex-column justify-content-between"  >
                        <h3>Login to your account</h3>

                        <form className="d-flex flex-wrap gap-3 w-100" onSubmit={handleSubmit}>
                            <div className="col-12 col-md-4 " >
                                <input
                                    name="phone"
                                    className="form-control"
                                    placeholder="Enter phone number"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                            </div>
                            <div className="col-12 col-md-4">
                                <input
                                    name="password"
                                    className="form-control"
                                    type="password"
                                    placeholder="Enter password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                            <div className="col-12 col-md-2">
                                <Button
                                    style={{ height: '90%' }}
                                    type="primary"
                                    htmlType="submit"
                                    loading={isLoading} // Show loader when isLoading is true
                                    className="w-100 primary-button"
                                >
                                    Login
                                </Button>
                            </div>
                            {errorMessage && (
                                <div className="col-12 text-danger mt-2">
                                    {errorMessage}
                                </div>
                            )}
                        </form>

                        <p className="mt-2" onClick={showModal}>Forgot Password ?</p>

                        <div className="contact-us-section d-flex gap-2" >
                            <a onClick={ShowTerms} >Terms and conditions</a>
                            <a onClick={ShowPrivacy}>Privacy policy</a>
                            {/* <a href="/ContactUs">Contact us</a> */}
                        </div>

                    </div>

                    <div className="col-lg-2 d-flex flex-column align-items-end justify-content-end"  >

                        <button 
                        onClick={()=>window.location.href='/ContactUs'}
                        className="primary-button" >Help?</button>
                    </div>


                </div>
            </div>

            {errorMessage && (
                <Alert
                    className="ResetAlert"
                    message={errorMessage}
                    type="error"
                    showIcon
                />
            )}

            <Modal
                open={open}
                title="Reset Password"
                onOk={handleOk}
                onCancel={handleCancel}
                style={{ boxShadow: '2px 3px 20px 13px #08ff7f14', paddingBottom: '0px' }}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        loading={loading}
                        onClick={handleOk}
                        style={{ background: '#26C46D' }}
                    >
                        Submit
                    </Button>,
                ]}
            >
                <p>Please enter your phone number to send you your password by SMS.</p>
                <input
                    style={{ background: 'white', width: '100%' }}
                    placeholder="Enter Your phone number"
                    value={phoneNumber} // Bind the input value to the state
                    onChange={handleInputChange} // Update state as user types
                />
            </Modal>


            <Drawer title="Terms And Conditions" 
            onClose={CloseTerms} open={OpenTerms}>
                <iframe
                src="https://d2saof99ugqr6h.cloudfront.net/TermsAndConditions?id=8&country=LB&langcode=ar"
                >

                </iframe>
            </Drawer>

            <Drawer title="Privacy Policy" 
            onClose={ClosePrivacy} open={OpenPrivacy}>
               <iframe
               src="https://d2saof99ugqr6h.cloudfront.net/PrivacyPolicy?id=8&country=LB&langcode=en"
               ></iframe>
            </Drawer>

        </section>
    );
}
